import React, { useState } from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import PhoneIcon from '@material-ui/icons/Phone'
import EmailIcon from '@material-ui/icons/Email'
import contactUs from '../images/contactUs.svg'
import ContactModal from '../components/modals/contactModal'
const Contact = () => {
    const [openModal, setOpenModal] = useState(false)
    return (
        <div>
            <Layout>
                <Seo title="Company" description="BricoBrowser associated with Ricta Technologies" />
                <section className="bg-bgColor h-screen">
                    <header className="py-16 w-4/5 md:w-full max-w-screen-xl m-auto">
                        <div className="grid grid-cols-1 md:grid-cols-2">
                            <div className="items-center flex flex-col mb-4 md:mb-0">
                                <div className="text-xl sm:text-3xl  p-1">Contact US </div>
                                <div className="text-center md:text-left w-full lg:w-4/5 m-auto">
                                    <p className=" sm:text-xl font-normal mb-2"><span className="font-bold">Ricta Technologies SRL</span></p>
                                    <p className=" sm:text-xl font-normal mb-2"><span className="font-bold"><LocationOnIcon className="mr-1 md:mr-2"/>Address:</span>Bucharest, Romania </p>
                                    <p className=" sm:text-xl font-normal mb-2"><span className="font-bold"><PhoneIcon  className="mr-1 md:mr-2"/>Phone Number:</span> +40 312295114</p>
                                </div>
                                <p className=" sm:text-xl font-normal my-2 text-left" onClick={()=>setOpenModal(true)}><span className="font-bold cursor-pointer bg-mainColor rounded-md text-white p-2 px-4 "> <EmailIcon  className="mr-1 mb-1 md:mr-2"/>Contact Us !</span></p>
                            </div>
                            <div className="flex items-end justify-end w-3/5 m-auto lg:w-2/3">
                                <img src={contactUs}/>
                            </div>
                        </div>
                    </header>
                    {openModal && <ContactModal modalIsOpen={openModal} setModalIsOpen={setOpenModal}/>}
                </section>
            </Layout>
        </div>
    )

}
export default Contact